import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React, {useMemo} from 'react'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {KenticoPageLinkData} from '~/kentico/components/page-link'
import {KenticoStoreData, toStore} from '~/kentico/components/store'
import {
  KenticoStorePage,
  KenticoStorePageData,
} from '~/kentico/components/store-page'
import {LinkedItems, createUrl, linkedItems} from '~/kentico/util'
import {StoresProvider} from '~/stores/components/provider'
import {Store} from '~/stores/types'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    page: IO.intersection([
      KenticoStorePageData,
      IO.type({
        elements: IO.type({
          store: LinkedItems(KenticoStoreData),
        }),
      }),
    ]),
    pages: LinkedItems(
      IO.intersection([
        KenticoPageLinkData,
        IO.type({
          elements: IO.type({
            store: LinkedItems(
              IO.type({
                id: IO.string,
              }),
            ),
          }),
        }),
      ]),
    ),
    stores: LinkedItems(KenticoStoreData),
  }),
  pageContext: IO.type({
    storeIds: IO.array(IO.string),
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!, $storeIds: [String!]!, $pageIds: [String!]!) {
    page: kontentItemStorePage(id: {eq: $id}) {
      ...KenticoStorePage
      elements {
        store {
          nodes: value {
            ...KenticoStore
          }
        }
      }
    }
    stores: allKontentItemStore(filter: {id: {in: $storeIds}}) {
      nodes {
        ...KenticoStore
      }
    }
    pages: allKontentItemStorePage(filter: {id: {in: $pageIds}}) {
      nodes {
        ...KenticoPageLink
        elements {
          store {
            nodes: value {
              id
            }
          }
        }
      }
    }
    ...KenticoGlobalScripts
  }
`

const StorePage = (props: unknown) => {
  //console.log(props)
  const {
    data: {pages, stores, ...data},
    pageContext: {storeIds},
  } = useDecode(Props, props)

  // Ensure sorting matches original data
  const sortedStores = useMemo(
    () =>
      [...linkedItems(stores)]
        .sort((a, b) => storeIds.indexOf(a.id) - storeIds.indexOf(b.id))
        .map<Store>(node => {
          const store = toStore(node)
          const page = linkedItems(pages).find(p =>
            linkedItems(p.elements.store).some(s => s.id === node.id),
          )
          return page ? {...store, url: createUrl(page)} : store
        }),
    [pages, stores, storeIds],
  )

  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <StoresProvider
        // If the assertion fails, the build fails anyway
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        store={toStore(data.page.elements.store.nodes![0])}
        stores={sortedStores}
      >
        <KenticoStorePage {...data} />
      </StoresProvider>
    </GatsbyLayout>
  )
}

/** Store page. */
// eslint-disable-next-line import/no-default-export
export default StorePage
