import {KenticoBreadcrumbData} from './data'
import * as IO from 'io-ts'
import { Row as BootstrapRow } from '~/bootstrap/components/layout'
import React from 'react'
import {FaAngleRight, FaHome} from 'react-icons/fa'
import styled from 'styled-components'


/** Data type. */
export interface KenticoBreadcrumbData
  extends IO.TypeOf<typeof KenticoBreadcrumbData> {}

interface Props {
  breadcrumbData: KenticoBreadcrumbData | undefined | null
  endCrumb: string
}

const Row = styled(BootstrapRow)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    margin-left: 1em;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    margin-left: 0.8em;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    margin-left: 0.5em;
  }
`

export const BreadcrumbComponent = ({breadcrumbData, endCrumb}: Props) => {

const homeCrumb = <a href='\'><FaHome size={20}/></a>
const breadcrumbs = breadcrumbData?.elements.breadcrumb_item.value.map(({elements}) => (
  <><a href={elements.url.value}>{elements.label.value}</a><span><FaAngleRight/></span></>
))

  return (
    <Row>
      {homeCrumb}
      <span><FaAngleRight/></span>
      {breadcrumbs}
      {endCrumb}
    </Row>
  )
}
