import React, {useContext, useMemo} from 'react'
import {FaAngleLeft, FaCheckCircle} from 'react-icons/fa'
import {
  MdAccessTime,
  MdArrowDropDown,
  MdPhone,
  MdRecordVoiceOver,
} from 'react-icons/md'
import {UncontrolledCollapse} from 'reactstrap'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Link} from '~/common/components/link'
import {Noop} from '~/common/components/noop'
import {formatPhoneNumber} from '~/common/util'
import {GatsbySEO} from '~/gatsby/components/seo'
import {useQuery} from '~/gatsby/util'
import {StoresHours} from '~/stores/components/hours'
import {StoresMap} from '~/stores/components/map'
import {StoresContext} from '~/stores/context'
import {Store} from '~/stores/types'
import {createReplace} from '../../renderer'
import {createUrl, linkedItems} from '../../util'
import {KenticoBodyItem} from '../body-item'
import { BreadcrumbComponent } from '../breadcrumb/indext'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoGlobalScriptData} from '../global-script'
import {KenticoStorePageData} from './data'
import { BsExclamationCircle } from 'react-icons/bs'
import { NotificationMessage } from '~/stores/components/notificationMessage'

interface Props {
  page: KenticoStorePageData
  globalScripts: KenticoGlobalScriptData
}

/** Kentico type data. */
export {KenticoStorePageData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
`

const StoreDetail = styled.p`
  font-size: normal;

  > svg {
    margin-right: ${({theme}) => theme.spacers[2]};
  }
`

const StoreDescription = styled.div`
  h1{
    font-size:1.125rem !important;
  }
`

const NotificationWarning = styled.span`
  color: ${({theme}) => theme.colors.red};
`

const NearbyStoreContainer = styled.div`
  max-width: 50%;
`

const StoreDetailAddress = styled(StoreDetail)`
  margin-bottom: 0;
`

const Divider = styled.hr`
  margin: ${({theme}) => theme.spacers[3]} 0 ${({theme}) => theme.spacers[4]};
`

const LastDivider = styled(Divider)`
  margin-top: ${({theme}) => theme.spacers[4]};
`

const SectionTitle = styled.h5`
  margin-bottom: ${({theme}) => theme.spacers[4]};
`

const Checkmark = styled(FaCheckCircle)`
  color: ${({theme}) => theme.colors.tertiary};
  margin-right: ${({theme}) => theme.spacers[3]};
  font-size: 1.4rem;
`

const ServiceAvailable = styled(Col)`
  display: flex;
`

const NearbyLocation = styled(Col)`
  padding-bottom: ${({theme}) => theme.spacers[3]};

  & > p {
    margin-bottom: 0;
  }
`

const MapContainer = styled(Col)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    display: none;
  }
`

const SmallTextRow = styled(Row)`
  padding-bottom: ${({theme}) => theme.spacers[3]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-left: ${({theme}) => theme.spacers[2]};
  }
`

const DropdownCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const DropdownButton = styled(StoreDetail)`
  display: inline-block;
  cursor: pointer;
  align-items: center;
`

const OtherStoreLink = styled.a`
  color: ${({theme}) => theme.colors.secondary};

  &:hover {
    color: ${({theme}) => theme.colors.secondary};
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary};
  }
`

const StoreTitle = styled.h4`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-top: ${({theme}) => theme.spacers[3]};
  }
`

// The functions below are helpers, not components
/* eslint-disable react/no-multi-comp */

const createCashAdvanceLoanLink = (store: Store) => {
  if (!store.loans.cashAdvance) {
    return undefined
  }
  let cashAdvanceLoanText = <p>Cash Advance Loans</p>
  if (store.loanLinks && store.loanLinks.length > 0) {
    cashAdvanceLoanText = (
      <p>
        <Link to={createUrl(store.loanLinks[0].elements.cashAdvance)}>
          Cash Advance Loans
        </Link>
      </p>
    )
  }
  return (
    <ServiceAvailable sm="6">
      <Checkmark />
      {cashAdvanceLoanText}
    </ServiceAvailable>
  )
}

const createInstallmentLoanLink = (store: Store) => {
  if (!store.loans.installment) {
    return undefined
  }
  let installmentLoanText = <p>Installment Loans</p>
  if (store.loanLinks && store.loanLinks.length > 0) {
    installmentLoanText = (
      <p>
        <Link to={createUrl(store.loanLinks[0].elements.installment)}>
          Installment Loans
        </Link>
      </p>
    )
  }
  return (
    <ServiceAvailable sm="6">
      <Checkmark />
      {installmentLoanText}
    </ServiceAvailable>
  )
}

const createTitleLoanLink = (store: Store) => {
  if (!store.loans.title) {
    return undefined
  }
  let titleLoanText = <p>Title Loans</p>
  if (store.loanLinks && store.loanLinks.length > 0) {
    titleLoanText = (
      <p>
        <Link to={createUrl(store.loanLinks[0].elements.title)}>
          Title Loans
        </Link>
      </p>
    )
  }
  return (
    <ServiceAvailable sm="6">
      <Checkmark />
      {titleLoanText}
    </ServiceAvailable>
  )
}

/**
 * Render store page from Kentico data.
 * @return React component
 */
export const KenticoStorePage = ({page, globalScripts}: Props) => {
  const {store, stores: nearbyStores} = useContext(StoresContext)
  const {mapMarker} = useQuery()
  const stores = useMemo(() => (store ? [store] : []), [store])
  if (!store || !nearbyStores) {
    return <Noop />
  }
  const {storeDetailedDescription, breadcrumb} = page.elements

  let notification
  let notificationMessage
  if(store.notificationMessage && store.enableDallasStoreChanges){
    notification = <NotificationWarning><BsExclamationCircle /></NotificationWarning>
    notificationMessage = <NotificationMessage notificationText={store.notificationMessage}/>
  }

  let imageElement
  if (store.image) {
    imageElement = <KenticoFluidImage alt="Cash Store" image={store.image} />
  }

  let bilingualCheck
  if (store.bilingual) {
    bilingualCheck = (
      <StoreDetail>
        <MdRecordVoiceOver />
        Bilingual
      </StoreDetail>
    )
  }

  const cashAdvanceCheck = createCashAdvanceLoanLink(store)
  const installmentCheck = createInstallmentLoanLink(store)
  const titleLoanCheck = createTitleLoanLink(store)
  let closestLocations: any[] = []
  let nearbyStoreNotificationMessage: {} | null | undefined

  nearbyStores.forEach(currentStore => {
    let nearbyStoreNotification
    if (currentStore.notificationMessage && store.enableDallasStoreChanges) {
      nearbyStoreNotification = (
        <NotificationWarning>
          <BsExclamationCircle />
        </NotificationWarning>
      )
      nearbyStoreNotificationMessage = (
        <>
          <Divider />
          <NotificationMessage
            notificationText={currentStore.notificationMessage}
          />
        </>
      )
    }
    closestLocations.push(
      <NearbyStoreContainer>
        <NearbyLocation key={currentStore.number} sm>
          <StoreDetail>
            <OtherStoreLink href={currentStore.url}>
              Cash Store {nearbyStoreNotification}
            </OtherStoreLink>
          </StoreDetail>
          <p>{currentStore.address}</p>
          <p>
            {currentStore.city}, {currentStore.state} {currentStore.zip}
          </p>
          <a href={`tel:${currentStore.phone}`}>
            {formatPhoneNumber(currentStore.phone)}
          </a>
        </NearbyLocation>
      </NearbyStoreContainer>,
    )
  })

  let storeDescElement
  if (
    storeDetailedDescription.value.replace(/(<([^>]+)>)/gi, '').trim() !== ''
  ) {
    storeDescElement = (
      <>
        <Divider />
        <Row>
          <Col>
            <StoreDescription
              dangerouslySetInnerHTML={{
                __html: storeDetailedDescription.value,
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  let storeLocationLink = (
    <>
      <StoreDetailAddress>{store.address}</StoreDetailAddress>
      <StoreDetail>
        {store.city}, {store.state} {store.zip}
      </StoreDetail>
    </>
  )
  if (store.gmbUrl !== undefined) {
    storeLocationLink = (
      <a href={store.gmbUrl} rel="noopener noreferrer" target="_blank">
        {storeLocationLink}
      </a>
    )
  }

  const backToResults =
                  <>
                    <FaAngleLeft />
                    <Link to={createUrl(page.elements.backToLink)}>
                      Back to Results
                    </Link>
                  </>

// FIXME Code is too similar to store-location's, should be reused
  return (
    <>
      <GatsbySEO
        description={page.elements.description.value}
        globalScripts={globalScripts}
        page={page}
        pageScripts={page.elements.scripts}
        replace={createReplace}
        robots={page.elements.robots}
        schema={linkedItems(page.elements.schema)}
        title={page.elements.title.value}
      />
      <KenticoBodyItem data={linkedItems(page.elements.preBody)} />
      <Container>
        <Row>
          <Col lg="6" md="12">
            <SmallTextRow>
              <small>
                {breadcrumb.nodes ?
                  breadcrumb.nodes.length === 0 ?
                    backToResults :
                      <BreadcrumbComponent breadcrumbData={breadcrumb.nodes[0]} endCrumb={store.address}/> :
                  backToResults
                }

              </small>
            </SmallTextRow>
            <Row>
              <Col sm>{imageElement}</Col>
              <Col sm>
                <StoreTitle>Cash Store {notification}</StoreTitle>
                {storeLocationLink}
                <StoreDetail>
                  <MdPhone />
                  <a href={`tel:${store.phone}`}>
                    {formatPhoneNumber(store.phone)}
                  </a>
                </StoreDetail>
                {bilingualCheck}
                <DropdownButton id="timeDropdown">
                  <MdAccessTime />
                  <span>Store Hours</span>
                  <MdArrowDropDown />
                </DropdownButton>
                <UncontrolledCollapse toggler="#timeDropdown">
                  <Row>
                    <DropdownCol>
                      <StoresHours store={store} />
                    </DropdownCol>
                  </Row>
                </UncontrolledCollapse>
              </Col>
              {notificationMessage}
            </Row>
            <Divider />
            <Row>
              <Col sm="12">
                <SectionTitle>Services Available</SectionTitle>
              </Col>
              {cashAdvanceCheck}
              {installmentCheck}
              {titleLoanCheck}
            </Row>
            <Divider />
            <Row>
              <Col sm="12">
                <SectionTitle>Nearby Locations</SectionTitle>
              </Col>
              {closestLocations}
              {nearbyStoreNotificationMessage}
            </Row>
          </Col>
          <MapContainer lg="6" md="12">
            <StoresMap icon={mapMarker} stores={[stores[0]]} zoom={14} />
          </MapContainer>
        </Row>
        {storeDescElement}
        <LastDivider />
      </Container>
      <KenticoBodyItem data={linkedItems(page.elements.postBody)} />
    </>
  )
}
