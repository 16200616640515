import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoBodyItemData} from '../body-item'
import {KenticoBreadcrumbData} from '../breadcrumb/data'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPageScriptData} from '../page-script'
import {KenticoRichTextData} from '../rich-text'
import {KenticoSchemaData} from '../schema'



/** Page Store. */
export const KenticoStorePageData = IO.intersection(
  [
    KenticoPageLinkData,
    IO.type({
      elements: IO.type({
        backToLink: LinkedItems(KenticoPageLinkData),
        breadcrumb: LinkedItems(KenticoBreadcrumbData),
        canonicalPage: LinkedItems(KenticoPageLinkData),
        description: IO.type({
          value: IO.string,
        }),
        postBody: LinkedItems(KenticoBodyItemData),
        preBody: LinkedItems(KenticoBodyItemData),
        robots: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.string,
            }),
          ),
        }),
        schema: LinkedItems(KenticoSchemaData),
        scripts: LinkedItems(KenticoPageScriptData),
        storeDetailedDescription: KenticoRichTextData,
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        id: IO.string,
      }),
    }),
  ],
  'KenticoStorePage',
)

/** Data type. */
export interface KenticoStorePageData
  extends IO.TypeOf<typeof KenticoStorePageData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoStorePage on Node {
    ... on kontent_item_store_page {
      ...KenticoPageLink
      system {
        id
      }
      elements {
        backToLink: back_to_link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        preBody: pre_post_body__pre_body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        postBody: pre_post_body__post_body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        canonicalPage: metadata__canonical_page {
          nodes: value {
            ...KenticoPageLink
          }
        }
        description: metadata__description {
          value
        }
        robots: metadata__robots {
          value {
            codename
          }
        }
        schema: metadata__schema {
          nodes: value {
            ...KenticoSchema
          }
        }
        scripts: metadata__scripts {
          nodes: value {
            ...KenticoPageScript
          }
        }
        storeDetailedDescription: store_detailed_description {
          ...KenticoRichText
        }
        title: metadata__title {
          value
        }
        breadcrumb: breadcrumb {
          nodes: value {
            ... on kontent_item_store_breadcrumb {
              elements {
                breadcrumb_item {
                  value {
                    ... on kontent_item_store_breadcrumb_item {
                      id
                      elements {
                        label {
                          value
                        }
                        url {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
