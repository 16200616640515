import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'

/** Loan Type Links. */
export const KenticoLoanTypeLinksData = IO.intersection([
  KenticoContentData,
  IO.type({
    elements: IO.type({
      cashAdvance: LinkedItems(KenticoPageLinkData),
      installment: LinkedItems(KenticoPageLinkData),
      title: LinkedItems(KenticoPageLinkData),
    }),
    system: IO.type({
      type: IO.literal('loan_type_links'),
    }),
  }),
])

/** Data type. */
export interface KenticoLoanTypeLinksData
  extends IO.TypeOf<typeof KenticoLoanTypeLinksData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoLoanTypeLinks on Node {
    ... on kontent_item_loan_type_links {
      system {
        codename
        id
        name
        type
      }
      elements {
        cashAdvance: cash_advance {
          nodes: value {
            ...KenticoPageLink
          }
        }
        installment {
          nodes: value {
            ...KenticoPageLink
          }
        }
        title {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
    }
  }
`
