import {setHours, startOfDay} from 'date-fns'
import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Store, StoreHours} from '~/stores/types'
import {LinkedItems, linkedItems, validImage} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoLoanTypeLinksData} from '../loan-type-links'

/** Store. */
export const KenticoStoreData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        address: IO.type({
          value: IO.string,
        }),
        city: IO.type({
          value: IO.string,
        }),
        fridayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        fridayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        gmbLocationUrl: IO.partial({
          value: IO.union([IO.string, IO.null]),
        }),
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        latitude: IO.type({
          value: IO.number,
        }),
        loanTypeLinks: LinkedItems(KenticoLoanTypeLinksData),
        loanTypes: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.keyof({
                cash_advance: undefined,
                installment: undefined,
                title: undefined,
              }),
            }),
          ),
        }),
        longitude: IO.type({
          value: IO.number,
        }),
        mondayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        mondayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        number: IO.type({
          value: IO.string,
        }),
        options: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.keyof({
                bilingual: undefined,
                open: undefined,
              }),
            }),
          ),
        }),
        phone: IO.type({
          value: IO.string,
        }),
        saturdayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        saturdayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        // TODO specific typing
        state: IO.type({
          value: IO.string,
        }),
        sundayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        sundayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        thursdayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        thursdayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        tuesdayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        tuesdayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        wednesdayClose: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        wednesdayOpen: IO.partial({
          value: IO.union([IO.number, IO.null]),
        }),
        zip: IO.type({
          value: IO.string,
        }),
        notificationMessage: IO.type({
          value: IO.string,
        })
      }),
      id: IO.string,
      system: IO.type({
        type: IO.literal('store'),
      }),
    }),
  ],
  'KenticoStore',
)

/** Data type. */
export interface KenticoStoreData extends IO.TypeOf<typeof KenticoStoreData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoStore on Node {
    ... on kontent_item_store {
      system {
        codename
        id
        name
        type
      }
      elements {
        # Store information
        image: main_photo {
          value {
            ...KenticoFluidImage
          }
        }
        options: store_options {
          value {
            codename
          }
        }
        number: store_number {
          value
        }
        phone {
          value
        }
        loanTypes: loan_types {
          value {
            codename
          }
        }
        loanTypeLinks: loan_type_links {
          nodes: value {
            ...KenticoLoanTypeLinks
          }
        }
        gmbLocationUrl: gmb_location_url {
          value
        }

        # Store hours
        mondayOpen: monday_open {
          value
        }
        mondayClose: monday_close {
          value
        }
        tuesdayOpen: tuesday_open {
          value
        }
        tuesdayClose: tuesday_close {
          value
        }
        wednesdayOpen: wednesday_open {
          value
        }
        wednesdayClose: wednesday_close {
          value
        }
        thursdayOpen: thursday_open {
          value
        }
        thursdayClose: thursday_close {
          value
        }
        fridayOpen: friday_open {
          value
        }
        fridayClose: friday_close {
          value
        }
        saturdayOpen: saturday_open {
          value
        }
        saturdayClose: saturday_close {
          value
        }
        sundayOpen: sunday_open {
          value
        }
        sundayClose: sunday_close {
          value
        }
        notificationMessage: notification_message{
          value
        }

        # Location
        address {
          value
        }
        city {
          value
        }
        state {
          value
        }
        zip {
          value
        }
        latitude {
          value
        }
        longitude {
          value
        }
      }
      id
    }
  }
`

const buildHours = (
  open: {value?: number | null},
  close: {value?: number | null},
): StoreHours | undefined => {
  const today = startOfDay(new Date(0))
  if (open.value == undefined || close.value == undefined) {
    return undefined
  }
  return {
    close: setHours(today, close.value + 12),
    open: setHours(today, open.value),
  }
}

/**
 * Convert Kentico store to standard store.
 * @return Store
 */
export const toStore = ({
  elements: {
    address,
    city,
    fridayClose,
    fridayOpen,
    gmbLocationUrl,
    image,
    latitude,
    loanTypeLinks,
    loanTypes,
    longitude,
    mondayOpen,
    mondayClose,
    number: storeNumber,
    options,
    phone,
    saturdayClose,
    saturdayOpen,
    sundayClose,
    sundayOpen,
    thursdayOpen,
    thursdayClose,
    tuesdayClose,
    tuesdayOpen,
    wednesdayClose,
    wednesdayOpen,
    state,
    zip,
    notificationMessage,
  },
}: KenticoStoreData): Store => {
  validImage(image)
  return {
    address: address.value,
    bilingual: options.value.some(o => o.codename === 'bilingual'),
    city: city.value,
    gmbUrl:
      gmbLocationUrl.value != undefined && gmbLocationUrl.value !== ''
        ? gmbLocationUrl.value
        : undefined,
    hours: {
      friday: buildHours(fridayOpen, fridayClose),
      monday: buildHours(mondayOpen, mondayClose),
      saturday: buildHours(saturdayOpen, saturdayClose),
      sunday: buildHours(sundayOpen, sundayClose),
      thursday: buildHours(thursdayOpen, thursdayClose),
      tuesday: buildHours(tuesdayOpen, tuesdayClose),
      wednesday: buildHours(wednesdayOpen, wednesdayClose),
    },
    image: image.value[0],
    loanLinks: linkedItems(loanTypeLinks),
    loans: {
      cashAdvance: loanTypes.value.some(o => o.codename === 'cash_advance'),
      installment: loanTypes.value.some(o => o.codename === 'installment'),
      title: loanTypes.value.some(o => o.codename === 'title'),
    },
    location: {
      lat: latitude.value,
      lng: longitude.value,
    },
    number: storeNumber.value,
    phone: phone.value,
    state: state.value,
    zip: zip.value,
    notificationMessage: notificationMessage.value,
  }
}
