import * as IO from 'io-ts'

export const KenticoBreadcrumbData = IO.type({
  elements: IO.type({
    breadcrumb_item: IO.type({
      value: IO.array(
        IO.type(
          {
            id:IO.string,
            elements:IO.type({
                label:IO.type({
                  value:IO.string
                }),
                url:IO.type({
                value:IO.string
               })
            }),
          }
        )
      )
    })
  })}
)
